import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import { makeStyles } from "@mui/styles";
import "./_DropdownMenu.scss";
import LogOutMenu from "./LogOutMenu";

import { fetchDepartment } from "../../slices/departement";
import Upload from "../../components/UploadImg/index";
import getAccess from "../../utilities/getAccess";

export const useStyles = makeStyles(
  {
    dropdownMenuiLogout: {
      "& .MuiPaper-root": {
        top: " 80px !important",

        border: "1px solid $lighter-gray-14 !important",
        width: "199px !important",
        borderRadius: "16px !important",
      },
      "& .jss2": {
        borderRadius: "16px !important",
      },

      "& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
        {
          "& .MuiList-padding ": {
            marginBottom: "0px !important",
          },
        },
    },
  },
  { index: 1 }
);

export default function AccountMenu(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.fetchMe);
  const { fetchMeStatus } = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (
      getAccess(
        user?.permissions,
        user?.permissionGroups?.[0]?.permissions,
        "DEPARTMENT",
        "LIST"
      )
    ) {
      dispatch(fetchDepartment());
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div onClick={handleClick}>
        {fetchMeStatus === "succeeded" && (
          <Upload
            img={user?.img}
            height={"41px"}
            width={"41px"}
            uploadPhoto={true}
            updatedClasse="icon-button_logout"
          />
        )}
      </div>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClick={handleClose}
        className={classes.dropdownMenuiLogout}
      >
        <LogOutMenu />
      </Menu>
    </>
  );
}
